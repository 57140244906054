<template>
  <div class="manage-project-booking">
    <div class="container fluid">
      <fd-tabs route>
        <fd-route-tab
          label="Pending"
          :to="{ name: 'ManageProjectBookingPending' }"
        >
        </fd-route-tab>
        <fd-route-tab
          label="Rejected"
          :to="{ name: 'ManageProjectBookingRejected' }"
        >
        </fd-route-tab>
        <fd-route-tab
          label="Booked By Others"
          :to="{ name: 'ManageProjectBookingBookedByOther' }"
        >
        </fd-route-tab>
      </fd-tabs>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {},
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
</style>
